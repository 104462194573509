import * as React from "react";
import * as Styles from '../styles/layout.module.scss'

const View = ({ title, children }) => {

  return (    
      <div className={Styles.view}>        
       {title?(<h1>{title}</h1>):null} 
        {children}
      </div>
  );
};
export default View;