import React from "react"
import Layout from "../components/Layout"
import View from "../components/View"

const NotFound = () => (
  <Layout>
    <View title="Seite nicht gefunden">
      <p>Meistens ist dann ein Link kaputt. Bitte melden Sie das. Alternativ die Seite bitte noch einmal Laden (F5), manchmal funktioniert es dann.</p>
    </View>
  </Layout>
)

export default NotFound
